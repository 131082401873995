import {RouterModule, Routes} from '@angular/router'
import {NgModule} from "@angular/core"
import {CommonModule} from "@angular/common"
import {AppComponent} from "./app.component"
import {appGuard} from "./app.guard"

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [appGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'welcome'
      },
      {
        path: 'ddesmobilize',
        loadChildren: () => import('./application/ddesmobilize/logged/logged.module').then(m => m.LoggedModule)
      },
      {
        path: 'welcome',
        loadChildren: () => import('./application/ddesmobilize/unlogged/unlogged.module').then(m => m.UnloggedModule)
      },
    ],
  },
  {
    path: '**', pathMatch: 'full', redirectTo: 'welcome'
  }
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
})
export class AppRouterModule {
}
