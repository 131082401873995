import {HttpInterceptorFn} from "@angular/common/http"
import {tap} from "rxjs"
import {inject} from "@angular/core"
import {AuthStateInterface} from "@shared/security/store/auth.state"
import AppFacade from "./store/facade/app.facade"
import CdkSessionStorageService from "./cdk/service/cdk-session-storage.service"
import {Router} from "@angular/router"

export const preFlightInterceptor: HttpInterceptorFn = (request, next) => {
  const authState: AuthStateInterface = inject(AppFacade).getAuthorization()
  const appFacade: AppFacade = inject(AppFacade)
  const router: Router = inject(Router)
  const localService: CdkSessionStorageService = inject(CdkSessionStorageService)

  appFacade.showLoading()

  const handleUrl = () => {
    const splited = request.url.split('.rule')
    return request.url.includes('api') ? splited[0] + `.rule?sys=ADV${splited[1]}` : request.url
  }

  const flight = request.clone({
    setHeaders: {
      accept: 'application/jsoncharset=UTF-8',
      Authorization: authState?.token || "",
    },
    url: handleUrl()
  })
  return next(flight).pipe(
    tap({
      error: (error: any) => {
        if (error.status === 401) {
          localService.wipe()
          router.navigateByUrl('/')
        }
        appFacade.hideLoading()
      }
    }))
}
