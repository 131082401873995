import { NgModule } from '@angular/core'
import {CdkLoadingComponent} from "./cdk-loading.component"


@NgModule({
  declarations: [
    CdkLoadingComponent
  ],
  exports: [
    CdkLoadingComponent
  ],
})
export class CdkLoadingModule { }
