import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from "@angular/core"
import {AppRouterModule} from "./app.router.module"
import {BrowserModule} from "@angular/platform-browser"
import {NgxsModule} from "@ngxs/store"
import {AppComponent} from "./app.component"
import AppState from "./store/app.state"
import {environment} from "../environments/environment"
import {provideHttpClient, withInterceptors} from "@angular/common/http"
import {RouterOutlet} from "@angular/router"
import {NgxsDispatchPluginModule} from "@ngxs-labs/dispatch-decorator"
import {provideNgxMask} from "ngx-mask"
import {preFlightInterceptor} from "./pre-flight.interceptor"
import {loggedInterceptor} from "./application/ddesmobilize/logged/logged.interceptor"
import AuthState from "./application/shared/security/store/auth.state"

import { registerLocaleData } from '@angular/common'
import localePt from '@angular/common/locales/pt'
import {CdkLoadingModule} from "@cdk/component/loading/cdk-loading.module"
registerLocaleData(localePt)

@NgModule({
  declarations: [
    AppComponent,
  ],
    imports: [
        BrowserModule,
        RouterOutlet,
        AppRouterModule,
        NgxsModule.forRoot([AppState, AuthState], {
            developmentMode: !environment.production
        }),
        NgxsDispatchPluginModule.forRoot(),
        CdkLoadingModule
    ],
  providers: [
    provideHttpClient(
      withInterceptors([
        preFlightInterceptor,
        loggedInterceptor
      ])
    ),
    provideNgxMask(),
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide:  DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
